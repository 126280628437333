<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-tooltip bottom v-if="item.id < 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  color="indigo"
                  class="ma-1"
                  @click="OpenFilterForm"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtre Avancé</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.id < 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  color="indigo"
                  class="ma-1"
                  @click="unfilrer"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!unfilter"
                >
                  <v-icon> mdi-filter-off </v-icon>
                </v-btn>
              </template>
              <span>Annuler Filtre</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-select
                  :items="depots_list"
                  v-model="editedItem.depot_id"
                  item-text="depot_name"
                  item-value="depot_id"
                  label="Depot"
                  :rules="[(v) => !!v || 'Depot obligatoire']"
                  :readonly="item.id > 0"
                  @input="changedepot"
                >
                </v-select>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="8">
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    item-text="label"
                    :item-value="'id'"
                    :items="filtred_products"
                    label="Produit"
                    dense
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    @keydown.enter="$refs.qte1.focus()"
                    :readonly="item.id > 0"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pmp"
                    readonly
                    label="PMP"
                    required
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tva_tx"
                    readonly
                    label="TVA"
                    required
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="qte1"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte1"
                    :readonly="readonly || editedItem.depot_statut != '1'"
                    :label="nbcompte == 2 ? '1er Comptage' : 'Comptage'"
                    required
                    @input="change1"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-menu
                    v-model="datepicker1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        v-model="editedItem.date1"
                        :label="
                          nbcompte == 2 ? 'Date 1er Comptage' : 'Date Comptage'
                        "
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :readonly="readonly || editedItem.depot_statut != '1'"
                        :rules="[
                          (v) =>
                            ((v || '').length == 0 &&
                              (editedItem.qte1 || '').length == 0) ||
                            !!v ||
                            'Date obligatoire',
                        ]"
                        dense
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.date1"
                      @input="datepicker1 = false"
                      :max="new Date().toISOString().substr(0, 10)"
                      v-if="!readonly && editedItem.depot_statut == '1'"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row dense v-if="nbcompte == 2">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="qte2"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte2"
                    :readonly="readonly || editedItem.depot_statut != '1'"
                    label="2eme Comptage"
                    required
                    @input="change2"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-menu
                    v-model="datepicker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        v-model="editedItem.date2"
                        label="Date 2eme Comptage"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :readonly="readonly || editedItem.depot_statut != '1'"
                        :rules="[
                          (v) =>
                            ((v || '').length == 0 &&
                              (editedItem.qte2 || '').length == 0) ||
                            !!v ||
                            'Date obligatoire',
                        ]"
                        dense
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.date2"
                      @input="datepicker2 = false"
                      :max="new Date().toISOString().substr(0, 10)"
                      v-if="!readonly && editedItem.depot_statut == '1'"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                dense
                v-if="
                  nbcompte == 2 &&
                  editedItem.qte1 > 0 &&
                  editedItem.qte2 > 0 &&
                  editedItem.qte1 != editedItem.qte2 &&
                  editedItem.depot_statut >= '2'
                "
              >
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="qte3"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte3"
                    :readonly="readonly || editedItem.depot_statut != '2'"
                    label="3eme Comptage"
                    required
                    @input="change3"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-menu
                    v-model="datepicker3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        v-model="editedItem.date3"
                        label="Date 3eme Comptage"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :readonly="readonly || editedItem.depot_statut != '2'"
                        :rules="[
                          (v) =>
                            ((v || '').length == 0 &&
                              (editedItem.qte3 || '').length == 0) ||
                            !!v ||
                            'Date obligatoire',
                        ]"
                        dense
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.date3"
                      @input="datepicker3 = false"
                      :max="new Date().toISOString().substr(0, 10)"
                      v-if="!readonly && editedItem.depot_statut == '2'"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="stock_phy"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.stock_phy"
                    :readonly="
                      readonly ||
                      editedItem.depot_statut != '3' ||
                      editedItem.stock_phy == editedItem.stock_cmpt
                    "
                    label="Stock Physique"
                    required
                    @input="change_phy"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5" v-if="editedItem.stock_phy_id">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.stock_phy_date"
                    label="Date Explication"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    :readonly="readonly"
                    label="Commentaire"
                    required
                    @keydown.enter="save"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <filterform :showForm="!isFilterClosed" @close="closeFilterForm">
    </filterform>
  </div>
</template>

<script>
function isAllInclude(arr1, arr2) {
  let hasAllElems = true;

  for (let i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i].toString()) === -1) {
      hasAllElems = false;
      break;
    }
  }

  return hasAllElems;
}
function isOneInclude(arr1, arr2) {
  let hasOneElems = false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i].toString()) != -1) {
      hasOneElems = true;
      break;
    }
  }

  return hasOneElems;
}
function filtrerParCat(arr, value) {
  return arr.filter(function (el) {
    if (el.categories) {
      let v = el.categories.split(",");
      if (isAllInclude(value, v)) return true;
      else return false;
    }
    return false;
  });
}
function filtrerParAtt(arr, value) {
  return arr.filter(function (el) {
    if (el.attributs) {
      let att = el.attributs.split(",");
      let ok = false;
      for (let index = 0; index < value.length; index++) {
        if (isOneInclude(value[index], att)) {
          ok = true;
          break;
        }
      }
      return ok;
    }
    return false;
  });
}
const filterform = () => import("./FilterForm.vue");
import CREATE_PRODUIT from "../graphql/Inventaire/CREATE_INVENT_DETAIL.gql";
import UPDATE_PRODUIT from "../graphql/Inventaire/UPDATE_INVENT_DETAIL.gql";

export default {
  components: {
    filterform,
  },
  name: "invdetailform",
  props: {
    item: Object,
    items: Array,
    invent_detail: Array,
    readonly: Boolean,
    showForm: Boolean,
    depots_list: Array,
    defaultdepot: String,
    nbcompte: Number,
    products: Array,
  },
  data: () => ({
    depot_id: "",
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    isProductClosed: true,
    product: {},
    template: 0,
    categories: [],
    attributs: [],
    isFilterClosed: true,
    unfilter: false,
    datepicker1: false,
    datepicker2: false,
    datepicker3: false,
    filtred_products: [],
  }),

  computed: {
    keyproduct() {
      return this.product ? this.product.id : 0;
    },

    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
    }
    if (this.item.id < 0) this.editedItem.depot_id = this.defaultdepot;
    this.changedepot();
  },

  methods: {
    changedepot() {
      let i;
      if (this.editedItem.depot_id) {
        i = this.depots_list.findIndex(
          (elm) => elm.depot_id == this.editedItem.depot_id
        );
        this.editedItem.depot_name = this.depots_list[i].depot_name;
        this.editedItem.depot_statut = this.depots_list[i].statut_id;
      }
      let t = this.products && this.editedItem.depot_id ? this.products : [];
      let l =
        this.items && this.editedItem.depot_id
          ? this.items.filter((elm) => elm.depot_id == this.editedItem.depot_id)
          : [];
      let yFilter = l.map((itemY) => {
        return itemY.produit_id;
      });
      yFilter = yFilter.filter((elm) => elm != this.item.produit_id);
      let filteredX = t.filter((itemX) => !yFilter.includes(itemX.id));
      //filtre by categorie
      if (this.categories.length > 0) {
        filteredX = filtrerParCat(filteredX, this.categories);
      }
      //filtre by template
      if (this.template) {
        filteredX = filteredX.filter((elm) => elm.fk_parent == this.template);
      }
      //filtre by attributs
      if (this.attributs.length > 0) {
        filteredX = filtrerParAtt(filteredX, this.attributs);
      }
      this.filtred_products = filteredX;
    },

    pad(n) {
      return n < 10 ? "0" + n : n;
    },
    datetime() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        this.pad(today.getMonth() + 1) +
        "-" +
        this.pad(today.getDate());
      var time =
        this.pad(today.getHours()) +
        ":" +
        this.pad(today.getMinutes()) +
        ":" +
        this.pad(today.getSeconds());
      return date + " " + time;
    },
    change1() {
      if (!this.editedItem.date1)
        this.editedItem.date1 = this.$store.state.today;
      this.editedItem.date_s1 = this.datetime();
      this.editedItem.qte1_id = this.$store.state.me.id;
      if (this.nbcompte == 1) this.editedItem.stock_phy = this.editedItem.qte1;
      if (this.editedItem.qte1 == this.editedItem.qte2)
        this.editedItem.stock_phy = this.editedItem.qte1;
      else this.editedItem.stock_phy = null;
      this.editedItem.qte1 =
        this.editedItem.qte1 == "" ? null : this.editedItem.qte1;
    },
    change2() {
      if (!this.editedItem.date2)
        this.editedItem.date2 = this.$store.state.today;
      this.editedItem.date_s2 = this.datetime();
      this.editedItem.qte3_id = this.$store.state.me.id;
      if (this.editedItem.qte1 == this.editedItem.qte2)
        this.editedItem.stock_phy = this.editedItem.qte1;
      else this.editedItem.stock_phy = null;
      this.editedItem.qte2 =
        this.editedItem.qte2 == "" ? null : this.editedItem.qte2;
    },
    change3() {
      if (!this.editedItem.date3)
        this.editedItem.date3 = this.$store.state.today;
      this.editedItem.date_s3 = this.datetime();
      this.editedItem.qte3_id = this.$store.state.me.id;
      this.editedItem.stock_phy = this.editedItem.qte3;
      this.editedItem.qte3 =
        this.editedItem.qte3 == "" ? null : this.editedItem.qte3;
    },
    change_phy() {
      this.editedItem.stock_phy_date = this.datetime();
      this.editedItem.stock_phy_id = this.$store.state.me.id;
    },
    OpenFilterForm() {
      this.isFilterClosed = false;
    },
    closeFilterForm(cat, temp, att) {
      if (cat || temp || att) {
        this.unfilter = true;
        this.categories = cat;
        this.template = parseInt(temp);
        this.attributs = att;
        //this.$store.dispatch("Changed", true);
      }
      this.isFilterClosed = true;
    },
    unfilrer() {
      this.unfilter = false;
      this.categories = [];
      this.attributs = [];
      this.template = 0;
      //this.$store.dispatch("Changed", true);
    },

    produit_change() {
      let i;
      this.editedItem.pmp = "";
      i = this.filtred_products.findIndex(
        (elm) => elm.id == this.editedItem.produit_id
      );
      if (i >= 0) {
        this.editedItem.pmp = this.filtred_products[i].pmp; //
        this.editedItem.tva_tx = this.filtred_products[i].tva_tx; //
        this.editedItem.stock_cmpt = this.filtred_products[i].stock_cmpt; //
        this.editedItem.label = this.filtred_products[i].label;
      }
    },
    closeProductForm() {
      this.product = {};
      this.isProductClosed = true;
    },
    close() {
      this.$emit("close", this.editedItem);
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v;
        if (this.editedItem.stock_phy != null) {
          this.editedItem.qte_ecart =
            this.editedItem.stock_phy - this.editedItem.stock_cmpt;
          this.editedItem.val_ecart =
            (this.editedItem.stock_phy - this.editedItem.stock_cmpt) *
            this.editedItem.pmp;
        }
        if (this.editedItem.id > 0) {
          v = {
            inventdetail: {
              id: this.editedItem.id,
              depot_id: this.editedItem.depot_id,
              produit_id: this.editedItem.produit_id,

              qte1: this.editedItem.qte1,
              qte2: this.editedItem.qte2,
              qte3: this.editedItem.qte3,
              stock_phy: this.editedItem.stock_phy,

              date1: this.editedItem.date1,
              date2: this.editedItem.date2,
              date3: this.editedItem.date3,

              date_s1: this.editedItem.date_s1,
              date_s2: this.editedItem.date_s2,
              date_s3: this.editedItem.date_s3,
              stock_phy_date: this.editedItem.stock_phy_date,

              qte1_id: this.editedItem.qte1_id,
              qte2_id: this.editedItem.qte2_id,
              qte3_id: this.editedItem.qte3_id,
              stock_phy_id: this.editedItem.stock_phy_id,

              pmp: this.editedItem.pmp,
              stock_cmpt: this.editedItem.stock_cmpt,
              tva_tx: this.editedItem.tva_tx,

              comment: this.editedItem.comment,

              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_PRODUIT, v);
          this.items.splice(this.editedItem.index, 1, this.editedItem);
          let i = this.invent_detail.findIndex(
            (elm) =>
              elm.depot_id == this.editedItem.depot_id &&
              elm.produit_id == this.editedItem.produit_id
          );
          this.invent_detail.splice(i, 1, this.editedItem);
          this.$emit("update", this.editedItem);
        } else {
          v = {
            inventdetail: {
              inv_id: this.item.inv_id,
              depot_id: this.editedItem.depot_id,
              produit_id: this.editedItem.produit_id,

              qte1: this.editedItem.qte1,
              qte2: this.editedItem.qte2,
              qte3: this.editedItem.qte3,
              stock_phy: this.editedItem.stock_phy,

              date1: this.editedItem.date1,
              date2: this.editedItem.date2,
              date3: this.editedItem.date3,

              date_s1: this.editedItem.date_s1,
              date_s2: this.editedItem.date_s2,
              date_s3: this.editedItem.date_s3,
              stock_phy_date: this.editedItem.stock_phy_date,

              qte1_id: this.editedItem.qte1_id,
              qte2_id: this.editedItem.qte2_id,
              qte3_id: this.editedItem.qte3_id,
              stock_phy_id: this.editedItem.stock_phy_id,

              pmp: this.editedItem.pmp,
              stock_cmpt: this.editedItem.stock_cmpt,
              tva_tx: this.editedItem.tva_tx,

              comment: this.editedItem.comment,

              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            },
          };

          v = await this.maj(CREATE_PRODUIT, v);
          this.editedItem.id = v.createInventDetail.id;
          this.items.splice(
            this.editedItem.index ? this.editedItem.index : 0,
            0,
            this.editedItem
          );
          this.$emit("add", this.editedItem.index);
          this.invent_detail.splice(
            this.editedItem.index ? this.editedItem.index : 0,
            0,
            this.editedItem
          );
        }
      }
    },
  },
};
</script>
